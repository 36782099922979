<template>
  <div>
    <portal to="body-top">
      <div class="d-flex justify-content-center">
        <b-skeleton class="w-50 h-25" />
      </div>
    </portal>
    <div class="bg-white rounded my-1">
      <div class="rounded border d-flex">
        <div
          style="width: 16.6%"
          class="d-flex flex-column"
        >
          <span
            style="width: 100%; height: 55px"
            class="border-bottom d-flex align-items-center justify-content-center"
          >
            <b-skeleton class="d-flex align-items-center w-75 h-50 mt-1" />
          </span>
          <span
            style="height: 15px"
            class="border-bottom"
          />
          <span
            style="height: 290px"
          >
            <b-skeleton
              class="h-100"
              style="width: 200px"
            />
          </span>
        </div>
        <div
          v-for="day in days(new Date())"
          :key="day"
          style="width: 16.8%"
          class="border-left d-flex flex-column"
        >
          <span
            style="width: 100%; height: 55px"
            class="border-bottom d-flex align-items-center justify-content-center"
          >
            <b-skeleton class="d-flex align-items-center w-75 h-50 mt-1" />
          </span>
          <span
            style="height: 15px"
            class="border-bottom"
          />
          <span
            style="height: 290px"
          >
            <b-skeleton
              class="h-100"
              style="width: 200px"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BSkeleton } from 'bootstrap-vue'

export default {
  name: 'WeekTableSkeleton',
  components: { BSkeleton },
  data() {
    return {
      weekDay: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
    }
  },
  methods: {
    days(current) {
      const week = []
      // Starting Monday not Sunday
      const first = current.getDate() - current.getDay() + 1
      current.setDate(first)
      for (let i = 0; i < 5; i++) {
        const weekDay = String(new Date(+current).toLocaleDateString('en-us', { weekday: 'short', day: 'numeric' })).split(' ').reverse().join(' ')
        week.push(weekDay)
        current.setDate(current.getDate() + 1)
      }
      return week
    },
  },
}
</script>
