<template>
  <div>
    <week-table :week-order="warehouseWeekOrder" />
  </div>
</template>
<script>
import WeekTable from '@/views/main/warehouse/main-order/components/tables/WeekTable.vue'
import config from '../../config'

export default {
  name: 'Week',
  components: { WeekTable },
  computed: {
    warehouseWeekOrder() {
      return this.$store.state[this.MODULE_NAME].warehouseWeekOrder
    },
  },
  setup() {
    const { MODULE_NAME } = config()
    return {
      MODULE_NAME,
    }
  },
}
</script>
